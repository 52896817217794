import React, { useState } from 'react';

const InputForm = ({ onCalculate }) => {
  const [distanceKm, setDistanceKm] = useState('');
  const [efficiencyMpg, setEfficiencyMpg] = useState('');
  const [gasPricePerLiter, setGasPricePerLiter] = useState('');

  const handleCalculate = () => {
    onCalculate({
      distanceKm: parseFloat(distanceKm),
      efficiencyMpg: parseFloat(efficiencyMpg),
      gasPricePerLiter: parseFloat(gasPricePerLiter),
    });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4 offset-md-4">
          <h2>ការគណនាតម្លៃប្រេងឥន្ធនៈ</h2>
          <form>
            <div className="form-group">
              <label htmlFor="distanceKm">ចម្ងាយធ្វើដំណើរ (KM):</label>
              <input
                type="number"
                className="form-control"
                id="distanceKm"
                value={distanceKm}
                onChange={(e) => setDistanceKm(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="efficiencyMpg">ប្រសិទ្ធភាពប្រេងឥន្ធនៈ (MPG):</label>
              <input
                type="number"
                className="form-control"
                id="efficiencyMpg"
                value={efficiencyMpg}
                onChange={(e) => setEfficiencyMpg(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="gasPricePerLiter">តម្លៃប្រេង ($/liter):</label>
              <input
                type="number"
                className="form-control"
                id="gasPricePerLiter"
                value={gasPricePerLiter}
                onChange={(e) => setGasPricePerLiter(e.target.value)}
              />
            </div>
            <div className="form-group">
            <button
              type="button"
              className="btn btn-primary btn-lg col-md-4"
              onClick={handleCalculate}
            >
              គណនា
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InputForm;
