import React, { useState } from 'react';
import './App.css';
import InputForm from './components/InputForm';
import FuelCostResult from './components/FuelCostResult';

function App() {
  const [fuelCost, setFuelCost] = useState(0);

  const calculateFuelCost = ({
    distanceKm,
    efficiencyMpg,
    gasPricePerLiter,
  }) => {
    if (
      !isNaN(distanceKm) &&
      !isNaN(efficiencyMpg) &&
      !isNaN(gasPricePerLiter)
    ) {
      // Convert MPG to kilometers per liter (1 mile = 1.60934 kilometers, 1 gallon = 3.78541 liters)
      const efficiencyKmpl = (efficiencyMpg * 1.60934) / 3.78541;
      // Calculate cost based on kilometers and kilometers per liter
      const cost = (distanceKm / efficiencyKmpl) * gasPricePerLiter;
      setFuelCost(cost);
    }
  };

  return (
    <div className="App">     
      <InputForm onCalculate={calculateFuelCost} />
      <FuelCostResult fuelCost={fuelCost} />
    </div>
  );
}

export default App;
